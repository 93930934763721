<template>
  <div>
    <list-layout ref="govPost" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter="{pageData}">
        <post-list-filter
            is-gov
            :filterForm="filterForm"
            :page="pageData.page"
            :uploadFilter="ok" />
      </template>
      <template #item_is_recommend="{row}">
        {{row.is_recommend ? '是' : '--'}}
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovPosts } from "@/modules/gov/api/post-library";
import PostListFilter from "@/modules/community-admin/components/postAdmin/ListFilter";
export default {
  components: {PostListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        group_id: -1,
        group_name: '',
        topic_id: this.$route.params.topic_id || -1,
        topic_name: '',
        top_status: -1,
        content: "", // 帖子内容
        author_name: "", // 创建者名称
        recommend_status: -1, // 推荐状态
        create_start_time: -1, //开始时间
        create_end_time: -1, //结束时间
        top_start_time: -1, // 置顶开始时间
        top_end_time: -1, //置顶结束时间
        tab: "checked", //当前筛选tab
        page_size: 15, //每页多少条数据
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "帖子内容", prop: "content", minWidth: 220 },
        { label: "发帖人", prop: "author_name", minWidth: 130 },
        { label: "所属话题", prop: "topic_name", minWidth: 110 },
        { label: "所属小组", prop: "group_name", minWidth: 110 },
        {
          label: "浏览/点赞/评论/收藏/分享",
          prop: "count",
          minWidth: 220,
          sortable: true,
        },
        {
          label: "举报数",
          prop: "complaint_count",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "发布时间",
          prop: "create_time",
          minWidth: 180,
          sortable: true,
        },
        {
          label: "置顶时间",
          prop: "top_time",
          minWidth: 320,
          sortable: true,
        },
        {
          label: "精华帖",
          prop: "is_recommend",
          minWidth: 80,
          type: 'slot'
        },
        {
          label: "操作", type: "operation", width: 80, operations: [{command: 'edit', text: '查看'}]
        }
      ]
    }
  },
  methods: {
    onEdit(row) {
      if (this.$route.name === 'GovTopicPostsIndex') {
        this.$router.push({
          name: 'GovTopicPostDetail',
          params: {
            id: row.id
          }
        })
      } else this.$router.push({
        name: "GovPostDetail",
        params: {
          id: row.id
        }
      })
    },
    getList(q) {
      return getGovPosts(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govPost.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
